<template>
  <div>
    <div class="wrapper">
      <img src="@/assets/images/logo-dark.svg" alt="" v-if="!$vuetify.breakpoint.smAndDown" />
      <div class="activation__inner" v-if="isSuccess">
        <h1 class="text-center" :class="{ 'text-h6': $vuetify.breakpoint.smAndDown }">
          {{ $t("auth.welcome") }}
        </h1>
        <span
          >{{ $t("auth.respected") }} <b>{{ user.object.name }} {{ user.object.surname }}</b></span
        >
        <span class="text-center">
          {{ $t("auth.successfully_accepted_email") }}
        </span>
        <v-btn color="success" @click="$router.push('/')">
          {{ $t("auth.access_platform") }}
        </v-btn>
        <h2>{{ $t("auth.sincerely") }}</h2>
      </div>
      
      <div class="activation__inner" v-else>
        {{ $t("auth.smt_went_wrong") }}
        <v-btn color="error" @click="$router.push('/register')">
          {{ $t("auth.back_to_main") }}
        </v-btn>
        <span>
          {{ $t("auth.try_again") }}
        </span>
            <div class="auth-language">
      <Language />
    </div>
      </div>
    </div>

  </div>
</template>

<script>
import requestService from "../../services/request/auth/authService";
import loader from "../../mixins/loader";
import user from "../../mixins/user";
import notifications from "../../mixins/notifications";
import Language from '../layout/Language.vue';

export default {
  name: "ActivationComponent",
  mixins: [loader, user, notifications],
  components: {
    Language
},
  data: () => ({
    isSuccess: false,
    user: {}
  }),
  async mounted() {
    this.setLoading(true);
    try {
      const userInfo = await requestService.usersActivate(this.$route.params.hash);
      this.user = userInfo;
      // orderDialog(this.user)
      this.checkActivation();
    } catch (e) {
      this.setErrorNotification(e);
    }
    this.setLoading(false);
  },
  methods: {
    checkActivation() {
      if (this.user?.status === "success") {
        this.isSuccess = true;
      } else {
        this.isSuccess = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  position: relative;
  img {
    position: absolute;
    top: 3%;
    left: 3%;
    width: 10%;
  }
  .activation__inner {
    position: relative;
    width: 800px;
    height: 614px;
    background: #fff;
    background-image: url("../../assets/images/Frame.svg");
    background-size: contain;
    justify-content: center;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    button {
      margin-top: 50px;
    }
    p {
      &:nth-child(2) {
        margin-top: 50px;
      }
    }
    h2 {
      font-size: 12px;
      font-weight: 300;
      margin-top: 150px;
    }
  }
}
.auth-language{
  position: absolute;
  top: 3%;
  right: 3%;
}
</style>
