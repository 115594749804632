<template>
    <ActivationComponent />
</template>

<script>
import ActivationComponent from '../components/activation/ActivationComponent.vue';

export default {
  name: 'Activation',
  components: {
    ActivationComponent,
  },
};

</script>

<style scoped>

</style>
